var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"dc6da54c8ec1c808a72f9a0f332f041ec25dcb82_0.71.0"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

/*
 * Copyright © 2024 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */

import { init, Replay } from '@sentry/nextjs'

import { ENV_NAME, REACT_APP_VERSION, SENTRY_DSN } from './src/config'

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

init({
  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,
  dsn: SENTRY_DSN,
  environment: ENV_NAME,
  integrations: [new Replay()],
  release: REACT_APP_VERSION,
  replaysOnErrorSampleRate: 1.0,
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,
  tracesSampleRate: 1.0,
})
